<template>
  <div
    class="p-2"
    :class="{ 'area-busy': waiting }"
  >
    <FormulateForm
      v-model="formData"
      name="meterForm"
      :class="{ 'view-only': viewOnly }"
      @submit="submitHandler"
    >
      <div class="grid grid-cols-2 gap-4">
        <div>
          <h6>Meter Information</h6>
          <hr class="divide-y">
          <FormulateInput
            name="Serial No"
            type="number"
            label="Serial No"
            placeholder="Serial No"
            validation="required|number|min:8,length|max:8,length"
            :validation-messages="{
              min: 'Serial No must be 8 characters long.',
              max: 'Serial No must be 8 characters long.',
            }"
            validation-behavior="live"
            :disabled="viewOnly"
            error-behavior="live"
          />

          <FormulateInput
            name="Aquifers"
            type="select"
            :options="aquifersTypeOptions"
            label="Aquifers"
            placeholder="Aquifers"
            validation-behavior="live"
            error-behavior="live"
            :disabled="viewOnly"
            validation="required"
          />

          <FormulateInput
            name="Well No"
            type="text"
            label="Well No"
            placeholder="Well No"
            validation-behavior="live"
            error-behavior="live"
            :disabled="viewOnly"
            validation="required"
          />

          <FormulateInput
            name="Meter Size"
            type="select"
            :options="meterSizeOptions"
            label="Meter Size"
            placeholder="Meter Size"
            validation-behavior="live"
            error-behavior="live"
            validation="required"
            :disabled="viewOnly"
          />

          <FormulateInput
            name="Meter Model"
            type="select"
            :options="meterModelOptions"
            label="Meter Model"
            placeholder="Meter Model"
            validation-behavior="live"
            error-behavior="live"
            :disabled="viewOnly"
            validation="required"
          />

          <FormulateInput
            name="Water Source"
            type="select"
            :options="waterSourceTypeOptions"
            label="Water Source"
            placeholder="Water Source"
            validation-behavior="live"
            error-behavior="live"
            :disabled="viewOnly"
            validation="required"
          />

          <FormulateInput
            name="Pump Type"
            type="select"
            :options="pumpTypeOptions"
            label="Pump Type"
            placeholder="Pump Type"
            validation-behavior="live"
            error-behavior="live"
            :disabled="viewOnly"
            validation="required"
          />

          <FormulateInput
            name="GPS Lat"
            type="text"
            label="GPS Lat"
            placeholder="GPS Lat"
            validation-behavior="live"
            error-behavior="live"
            :validation="[['matches', /^\d{1,3}[.]\d{1,6}$/]]"
            :disabled="viewOnly"
          />

          <FormulateInput
            name="GPS Long"
            type="number"
            label="GPS Long"
            placeholder="GPS Long"
            validation-behavior="live"
            error-behavior="live"
            :validation="[['matches', /^\d{1,3}[.]\d{1,6}$/]]"
            :disabled="viewOnly"
          />
          <div v-if="!viewOnly">
            <FormulateInput
              v-if="!formValues.showImage"
              type="image"
              name="Image"
              label="Image"
              validation-behavior="live"
              error-behavior="live"
              validation="required|mime:image/jpeg,image/png"
              :uploader="uploadFile"
              :value="formValues.Image"
              :disabled="viewOnly"
            />

            <FormulateInput
              v-else
              type="image"
              name="Image"
              label="Image"
              validation-behavior="live"
              error-behavior="live"
              validation="mime:image/jpeg,image/png"
              :uploader="uploadFile"
              :disabled="viewOnly"
            />
          </div>
          <b-img
            v-else
            :src="formValues.showImage"
            class="mb-2 cursor-pointer"
            @click="emitOpenImage(formValues.showImage)"
          />

          <FormulateInput
            name="Water Quality"
            type="number"
            label="Water Quality (TDS)"
            placeholder="Water Quality (TDS)"
            validation-behavior="live"
            error-behavior="live"
            :disabled="viewOnly"
            validation="number"
          />
        </div>

        <div>
          <h6>Consumer Information</h6>
          <hr class="divide-y">

          <FormulateInput
            name="Reference No"
            type="text"
            label="Reference No"
            placeholder="Reference No"
            validation-behavior="live"
            error-behavior="live"
            :disabled="viewOnly"
            validation="required|alphanumeric"
          />

          <FormulateInput
            name="Consumer Name"
            type="text"
            label="Consumer Name"
            placeholder="Consumer Name"
            validation-behavior="live"
            error-behavior="live"
            :disabled="viewOnly"
            validation="required"
          />

          <FormulateInput
            name="Consumer Name (Arabic)"
            dir="rtl"
            type="text"
            label="Consumer Name (Arabic)"
            placeholder="Consumer Name (Arabic)"
            validation-behavior="live"
            error-behavior="live"
            :disabled="viewOnly"
            validation="required"
          />

          <FormulateInput
            name="Consumer Type"
            type="select"
            :options="consumerTypeOptions"
            label="Consumer Type"
            placeholder="Consumer Type"
            validation-behavior="live"
            error-behavior="live"
            :disabled="viewOnly"
            validation="required"
          />

          <FormulateInput
            name="CR/CPR No"
            type="text"
            label="CR/CPR No"
            placeholder="CR/CPR No"
            validation-behavior="live"
            error-behavior="live"
            :disabled="viewOnly"
          />

          <FormulateInput
            name="Building"
            type="text"
            label="Building"
            placeholder="Building"
            validation-behavior="live"
            error-behavior="live"
            :disabled="viewOnly"
            validation="required"
          />

          <FormulateInput
            name="Block"
            type="text"
            label="Block"
            placeholder="Block"
            validation-behavior="live"
            error-behavior="live"
            :disabled="viewOnly"
            validation="required"
          />

          <FormulateInput
            name="Road"
            type="text"
            label="Road"
            placeholder="Road"
            validation-behavior="live"
            error-behavior="live"
            :disabled="viewOnly"
            validation="required"
          />

          <FormulateInput
            name="Plot No"
            type="text"
            label="Plot No"
            placeholder="Plot No"
            validation-behavior="live"
            error-behavior="live"
            :disabled="viewOnly"
            validation="required"
          />

          <FormulateInput
            name="Area"
            type="select"
            :options="areaTypeOptions"
            label="Area"
            placeholder="Area"
            validation-behavior="live"
            error-behavior="live"
            :disabled="viewOnly"
            validation="required"
          />

          <FormulateInput
            name="Governorate"
            type="select"
            :options="governorateTypeOptions"
            label="Governorate"
            placeholder="Governorate"
            validation-behavior="live"
            error-behavior="live"
            :disabled="viewOnly"
            validation="required"
          />

          <FormulateInput
            name="PO Box"
            type="text"
            label="PO Box"
            placeholder="PO Box"
            validation-behavior="live"
            error-behavior="live"
            validation="required"
            :disabled="viewOnly"
          />

          <FormulateInput
            name="Mobile"
            type="text"
            label="Mobile"
            placeholder="Mobile"
            validation-behavior="live"
            error-behavior="live"
            :disabled="viewOnly"
            validation="required|number"
          />

          <FormulateInput
            name="Email"
            type="text"
            label="Email"
            placeholder="Email"
            validation-behavior="live"
            error-behavior="live"
            :disabled="viewOnly"
            validation="required|email"
          />

          <FormulateInput
            name="Office Phone"
            type="text"
            label="Office Phone"
            placeholder="Office Phone"
            validation-behavior="live"
            error-behavior="live"
            :disabled="viewOnly"
            validation="number"
          />

          <FormulateInput
            name="Remark"
            type="textarea"
            label="Remark"
            placeholder="Remark"
            validation-behavior="live"
            error-behavior="live"
            :disabled="viewOnly"
            validation="max:50,length"
          />

          <FormulateInput
            name="Status"
            type="select"
            :options="customerStatusTypeOptions"
            label="Status"
            placeholder="Status"
            validation-behavior="live"
            error-behavior="live"
            :disabled="viewOnly"
            validation="required"
          />
        </div>
      </div>

      <b-button
        v-if="!viewOnly"
        variant="primary"
        :disabled="waiting"
        class="mr-1"
        block
        @click="$formulate.submit('meterForm')"
      >
        <span v-if="waiting"><b-spinner small /> Saving...</span>
        <span v-else>Save</span>
      </b-button>
    </FormulateForm>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BSpinner,
  BButton,
  BImg,
} from 'bootstrap-vue'
import {
  ref, computed, watch, onMounted,
} from '@vue/composition-api'
import store from '@/store'
import _ from 'lodash'
import useSideBar from './useSideBar'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BSpinner,
    BButton,
    BImg,
  },
  props: {
    formValues: {
      type: Object,
      required: true,
    },
    viewOnly: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  setup(props, { emit }) {
    const formData = ref(props.formValues)
    const vieOnlyProp = ref(props.viewOnly)
    const errorBehavior = ref('blur')

    const meterSizeOptions = computed(() => (store.state.app.enums
      ? _.cloneDeep(store.state.app.enums.MeterSizeType)
      : []))
    const meterModelOptions = computed(() => (store.state.app.enums
      ? _.cloneDeep(store.state.app.enums.MeterModelType)
      : []))
    const consumerTypeOptions = computed(() => (store.state.app.enums
      ? _.cloneDeep(store.state.app.enums.ConsumerType)
      : []))
    const aquifersTypeOptions = computed(() => (store.state.app.enums
      ? _.cloneDeep(store.state.app.enums.AquifersType)
      : []))
    const waterSourceTypeOptions = computed(() => (store.state.app.enums
      ? _.cloneDeep(store.state.app.enums.WaterSourceType)
      : []))
    const pumpTypeOptions = computed(() => (store.state.app.enums ? _.cloneDeep(store.state.app.enums.PumpType) : []))
    const governorateTypeOptions = computed(() => (store.state.app.enums
      ? _.cloneDeep(store.state.app.enums.GovernorateType)
      : []))
    const customerStatusTypeOptions = computed(() => (store.state.app.enums
      ? _.cloneDeep(store.state.app.enums.CustomerStatusType)
      : []))
    const areaTypeOptions = computed(() => (store.state.app.enums ? _.cloneDeep(store.state.app.enums.AreaType) : []))

    const {
      waiting,

      uploadFile,
      submitForm,
    } = useSideBar()

    watch(
      () => vieOnlyProp,
      value => {
        errorBehavior.value = value ? 'blur' : 'live'
      },
    )

    onMounted(() => {
      errorBehavior.value = props.viewOnly ? 'blur' : 'live'
    })

    const submitHandler = data => {
      submitForm(data)
        .then(() => {
          emit('success', true)
        })
        .catch(() => {})
    }

    const emitOpenImage = url => {
      emit('open-image-full', url)
    }

    return {
      formData,
      errorBehavior,

      uploadFile,
      submitHandler,

      waiting,

      meterSizeOptions,
      meterModelOptions,
      consumerTypeOptions,
      aquifersTypeOptions,
      waterSourceTypeOptions,
      pumpTypeOptions,
      governorateTypeOptions,
      customerStatusTypeOptions,
      areaTypeOptions,

      emitOpenImage,
    }
  },
}
</script>
