<template>
  <data-list-component
    :module="metersStoreModule"
    :use-list="useMetersList"
    :use-side-bar="useSideBar"
    module-name="app-meters"
  />
</template>

<script>
import DataListComponent from './DataListComponent.vue'
import useMetersList from './useMetersList'
import useSideBar from './useSideBar'
import metersStoreModule from './metersStoreModule'

export default {
  components: {
    DataListComponent,
  },
  data() {
    return {
      useMetersList,
      useSideBar,
      metersStoreModule,
    }
  },
}
</script>

<style></style>
